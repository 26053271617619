import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { navigate } from '@reach/router'
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import styled from "styled-components"
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../utils/htmlSerializer';
import HubspotForm from 'react-hubspot-form'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import LayoutUpdate from "../components/layout-update"
import Seo from "../components/seo"
import HeroSectionPageUpdate from "../components/common/hero-section-page-update";
import WistiaVideo from '../components/common/WistiaVideo';
import SectionMobileCTAnew from "../components/sections/section-mobile-cta-new";

const StyledDiv2 = styled.div`
  background-image: linear-gradient(to bottom, #1ea0a0, #ffffff);
`

const PageTestimonials = () => {
  const data = useStaticQuery(graphql`
    query PageTestimonialsQuery {
      prismicTestimonialsPage {
        _previewable
        data {
          testimonials_page_title
          testimonials_page_short_description
          cta_button
          how_many_buttons
          cta_button_one_type
          cta_button_text
          cta_button_one_hover_text
          cta_button_link {
            link_type
            uid
            url
          }
          cta_button_two_text
          show_red_bubble_section
          red_bubble_text
          intro_section_title
          intro_section_content {
            richText
          }
          intro_section_wistia_id
          page_hero_background {
            alt
            gatsbyImageData(
              width: 1920
            )
          }
          testimonial_section {
            title
            quote {
              richText
            }
            wistia_id
            video_position
            section_background_color
          }
        }
      }
      imgHero: file(relativePath: { eq: "backgrounds/hero-client-res-bg.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const doc = data.prismicTestimonialsPage
  const imgHero = getImage(doc.data.page_hero_background)
  const imgSrc = getSrc(doc.data.page_hero_background)

  return (
    <LayoutUpdate>
      <Seo
        title={doc.data.testimonials_page_title}
        description={doc.data.testimonials_page_short_description}
        image={imgSrc}
      />

      <div className="hero-section">
        <div className="relative pt-14 xl:pt-20" style={{ display: "grid", background: "#000000", minHeight: "400px" }}>
          <GatsbyImage
            style={{
              gridArea: "1/1",
              objectFit: "cover",
              opacity: "0.7",
              height: 400,
            }}
            alt="hero image"
            image={imgHero}
            formats={["auto", "webp", "avif"]}
          />

          <div
            style={{
              gridArea: "1/1",
              position: "relative",
              placeItems: "center",
              display: "grid",
            }}
          >
            <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2">
              <div className="flex flex-col justify-center">
                <h2 className="text-white text-4xl md:text-6xl font-extrabold text-center mt-4">
                  {doc.data.testimonials_page_title}
                </h2>                

                {doc.data.cta_button
                  ?
                  <div className="hidden xl:flex flex-col items-center justify-center mt-8">
                    {doc.data.cta_button_link.link_type === "Document" || doc.data.cta_button_link.link_type === "Any"
                      ?
                        <Link to={doc.data.cta_button_link.uid ? `/${doc.data.cta_button_link.uid}` : "/"}>
                          <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-12 py-3 mb-6">
                            <span className="default-text mx-2">{doc.data.cta_button_text}</span>
                            <span className="hover-text">{doc.data.cta_button_one_hover_text}</span>
                          </button>
                        </Link>
                      :
                      doc.data.cta_button_link.link_type === "Web" || doc.data.cta_button_link.link_type === "Media"
                      ?
                        <a
                          href={doc.data.cta_button_link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-12 py-3 mb-6">
                            <span className="default-text mx-2">{doc.data.cta_button_text}</span>
                            <span className="hover-text">{doc.data.cta_button_one_hover_text}</span>
                          </button>
                        </a>
                      :
                      null
                    }
                    
                    <button 
                      onClick={() => {navigate('#apply')}}
                      className="w-full mb-4 xl:mb-0 md:w-auto bg-white opacity-70 hover:opacity-90 text-base md:text-lg text-site-red uppercase tracking-wider px-12 py-3"
                    >
                      {doc.data.cta_button_two_text}
                    </button>
                  </div>
                  :
                  null
                }

                {doc.data.cta_button
                  ?
                  <div className="xl:hidden flex flex-col items-center justify-center mt-6">                    
                    <div className="mb-6">
                      <p className="text-white text-2xl font-semibold text-center tracking-wider mb-6">{doc.data.cta_button_text}</p>
                        {doc.data.cta_button_link.link_type === "Document" || doc.data.cta_button_link.link_type === "Any"
                        ?
                          <Link to={doc.data.cta_button_link.uid ? `/${doc.data.cta_button_link.uid}` : "/"}>
                            <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                              {doc.data.cta_button_one_hover_text}
                            </button>
                          </Link>
                        :
                        doc.data.cta_button_link.link_type === "Web" || doc.data.cta_button_link.link_type === "Media"
                        ?
                          <a
                            href={doc.data.cta_button_link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                              {doc.data.cta_button_one_hover_text}
                            </button>
                          </a>
                        :
                        null
                      }                      
                    </div>
                    
                    <button 
                      onClick={() => {navigate('#apply')}}
                      className="w-auto mb-4 xl:mb-0 bg-white opacity-70 hover:opacity-90 text-base md:text-lg text-site-red uppercase tracking-wider px-8 py-3"
                    >
                      {doc.data.cta_button_two_text}
                    </button>
                  </div>
                  :
                  null
                }                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative">
        {doc.data.show_red_bubble_section
          ?
          <div className="absolute top-0 w-full flex flex-col items-center -mt-16">
            <div className="w-11/12 lg:w-10/12 xl:w-11/12 max-w-screen-lg bg-red-800 mx-auto py-12 px-6 md:px-16 rounded-3xl shadow-lg">
              <h2 className="text-white font-medium text-center text-lg md:text-xl">{doc.data.red_bubble_text}</h2>
            </div>
          </div>
          :
          null
        }

        <StyledDiv2 className="py-20">
          <div className="green-gradient-slice-section w-11/12 lg:w-10/12 max-w-screen-lg mx-auto">
            {doc.data.intro_section_title
              ?
              <h2 className="text-3xl md:text-4xl text-white font-bold text-center">{doc.data.intro_section_title}</h2>
              :
              null
            }

            {doc.data.intro_section_content
              ?
              <RichText
                render={doc.data.intro_section_content.richText}
                htmlSerializer={htmlSerializer}
              />
              :
              null
            }

            <div className='shadow-lg mt-12'>
              <WistiaVideo wistiaID={doc.data.intro_section_wistia_id} />
            </div>
          </div>
        </StyledDiv2>

        {doc.data.testimonial_section.map(item => (
          <div className={`${item.section_background_color ? "bg-gray-100" : "bg-white"} pt-4 md:pt-24 pb-12 md:pb-24`}>
            <div className={`w-11/12 md:w-8/12 xl:w-10/12 max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto`}>
              <div className="relative w-full xl:w-1/2">
                <div className="z-0">
                  <svg className={`fill-current ${item.section_background_color ? "text-gray-200" : "text-site-green-light"} block h-48 w-60 -mb-36`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.928,3.932A9.709,9.709,0,0,0,.5,13.88v1.243a4.942,4.942,0,1,0,4.941-4.941,4.609,4.609,0,0,0-1.115.14.25.25,0,0,1-.277-.368A6.832,6.832,0,0,1,9.928,6.432a1.25,1.25,0,0,0,0-2.5Z"></path>
                    <path d="M22.25,6.432a1.25,1.25,0,0,0,0-2.5,9.71,9.71,0,0,0-9.428,9.948v1.243a4.942,4.942,0,1,0,4.942-4.941,4.611,4.611,0,0,0-1.116.14.249.249,0,0,1-.26-.092.252.252,0,0,1-.017-.276A6.832,6.832,0,0,1,22.25,6.432Z"></path>
                  </svg>
                </div>
                <div className="z-20">
                  <h2 className="text-site-gray-dark text-4xl md:text-5xl font-extrabold tracking-wider mb-8">{item.title}</h2>
                  <RichText
                    render={item.quote.richText}
                    htmlSerializer={htmlSerializer}
                  />
                </div>
              </div>
              <div className={`${item.video_position ? "order-last ml-0 xl:ml-8" : "xl:order-first mr-0 xl:mr-8"} w-full xl:w-1/2`}>
                <WistiaVideo wistiaID={item.wistia_id} />
              </div>
            </div>
          </div>
        ))}

        <div id="apply" className="bg-white flex flex-col justify-center items-center border-t-2 border-site-red pt-6 pb-12 md:pb-16">
          <div className="w-full mx-auto px-3">
            <h2 className="text-site-red text-4xl md:text-5xl text-center font-extrabold tracking-wider mb-8 mt-16">Apply to be an SRA Representative</h2>
          </div>

          <div className="w-11/12 max-w-lg mt-12 border-2 border-site-gray-dark rounded-3xl p-8 shadow-lg">
            <HubspotForm
              region='na1'
              portalId='23182726'
              formId='4af6a6b7-520f-49e6-b40c-82de61ec3eaa'
              loading={<div className='italic'>Loading apply form...</div>}
            />
          </div>
        </div>
      </div>
      <SectionMobileCTAnew />
    </LayoutUpdate>
  )
}

export default withPrismicPreview(PageTestimonials)
